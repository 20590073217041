import { downloads } from "../../utils/download";
import http from "../../utils/http";
export default {
	// 行业动态期刊号
	getIndustryDynamicSerialCode(params) {
		return http.get(`/mds/api/industry/getIndustryDynamicSerialCode`, params);
	},
	// 行业动态列表
	getIndustryDynamicPage(params) {
		return http.get(`/mds/api/industry/getIndustryDynamicPage`, params);
	},
	// 行业动态详情
	getIndustryDynamicInfo(params) {
		return http.get(`/mds/interceptApi/industry/getIndustryDynamicInfo`, params);
	},
	// 行业动态详情-文件流
	getIndustryDynamicFile(params) {
		return downloads(`/mds/interceptApi/industry/getIndustryDynamicFile`, params);
	},
	// 行业动态下载
	downloadIndustryDynamic(params) {
		return downloads(`/mds/interceptApi/industry/downloadIndustryDynamic`, params);
	},
	// 行业动态富文本下载
	downloadIndustryDynamicRich(params) {
		return downloads(`/mds/interceptApi/industry/downloadIndustryDynamicRich`, params);
	},
	// 政策规划列表
	getPolicyPlanPage(params) {
		return http.get(`/mds/api/industry/getPolicyPlanPage`, params);
	},
	//  政策规划详情
	getPolicyPlanInfo(params) {
		return http.get(`/mds/interceptApi/industry/getPolicyPlanInfo`, params);
	},
	//  政策规划url
	selectPolicyPlanUrlById(params) {
		return http.get(`/mds/api/industry/selectPolicyPlanUrlById/${params}`, params);
	},
	// 政策规划详情-文件流
	getPolicyPlanInfoFile(params) {
		return downloads(`/mds/interceptApi/industry/getPolicyPlanInfoFile`, params);
	},
	// 政策规划下载
	downloadPolicyPlan(params) {
		return downloads(`/mds/interceptApi/industry/downloadPolicyPlan`, params);
	},
	// 政策规划富文本下载
	downloadPolicyPlanRich(params) {
		return downloads(`/mds/interceptApi/industry/downloadPolicyPlanRich`, params);
	},
	// 资料报告列表
	getDatumReportPage(params) {
		return http.get(`/mds/api/industry/getDatumReportPage`, params);
	},
	// 资料报告分类
	getDatumReportType(params) {
		return http.get(`/mds/api/industry/getDatumReportType`, params);
	},
	// 资料报告分类（新）
	getFileType(params) {
		return http.get(`/mds/api/industry/getFileType`, params);
	},
	//  资料报告详情
	getDatumReportInfo(params) {
		return http.get(`/mds/interceptApi/industry/getDatumReportInfo`, params);
	},
	// 资料报告详情-文件流
	getDatumReportInfoFile(params) {
		return downloads(`/mds/interceptApi/industry/getDatumReportInfoFile`, params);
	},
	// 资料报告下载
	downloadDatumReport(params) {
		return downloads(`/mds/interceptApi/industry/downloadDatumReport`, params);
	},
	// 资料报告富文本下载
	downloadDatumReportRich(params) {
		return downloads(`/mds/interceptApi/industry/downloadDatumReportRich`, params);
	},
	// 经济数据库指标
	getSystemQuota(params) {
		return http.get(`/mds/api/industry/getSystemQuota`, params);
	},
	// 经济数据图标下载权限判断
	getEcoDataImgPermission() {
		return http.get(`/mds/api/industry/getEcoDataImgPermission`);
	},
	// 经济数据库-按地区-默认数据
	getEconomicDatabaseDefaultDataArea(params) {
		return http.post(`/mds/api/industry/getEconomicDatabaseDefaultDataArea`, params);
	},
	// 经济数据库-按地区
	getEconomicDatabaseByArea(params) {
		return http.post(`/mds/interceptApi/industry/getEconomicDatabaseByArea`, params);
	},
	// 经济数据库-按年份-默认数据
	getEconomicDatabaseDefaultDataYear(params) {
		return http.post(`/mds/api/industry/getEconomicDatabaseDefaultDataYear`, params);
	},
	// 经济数据库-按年份
	getEconomicDatabaseByYear(params) {
		return http.post(`/mds/interceptApi/industry/getEconomicDatabaseByYear`, params);
	},
	// 经济数据库-按指标-默认数据
	getEconomicDatabaseDefaultDataQuota(params) {
		return http.post(`/mds/api/industry/getEconomicDatabaseDefaultDataQuota`, params);
	},
	// 经济数据库-按指标
	getEconomicDatabaseByQuota(params) {
		return http.post(`/mds/interceptApi/industry/getEconomicDatabaseByQuota`, params);
	},
	// 省市区获取
	getAreaList(params) {
		return http.get(`/mds/api/industry/getAreaList`, params);
	},
	// 行业动态详情-推荐阅读
	getIndustryRecommended(params) {
		return http.get(`/mds/interceptApi/industry/getIndustryRecommended`, params);
	},
	// 政策规划详情-推荐阅读
	getPolicyPlanRecommended(params) {
		return http.get(`/mds/interceptApi/industry/getPolicyPlanRecommended`, params);
	},
	// 行业动态详情-推荐阅读
	getDatumReportRecommended(params) {
		return http.get(`/mds/interceptApi/industry/getDatumReportRecommended`, params);
	},
	// 经济数据下载
	getEconomicDatabaseDownload(params) {
		return downloads(`/mds/interceptApi/industry/getEconomicDatabaseDownload`, params, "post");
	},
	// 招标信息库-查询条件获取（省）
	getTenderInfoProvince(params) {
		return http.get(`/mds/api/tenderInfo/getTenderInfoProvince`, params);
	},
	// 招标信息库-查询条件获取（市）
	getTenderInfoCity(params) {
		return http.get(`/mds/api/tenderInfo/getTenderInfoCity`, params);
	},
	// 招标信息库列表
	getTenderInfo(params) {
		return http.get(`/mds/api/tenderInfo/getTenderInfo`, params);
	},
	// 招标信息库详情
	getTenderInfoDetail(params) {
		return http.get(`/mds/interceptApi/tenderInfo/getTenderInfoDetail`, params);
	},
	// 客户信息详情-判断是否有阅读次数
	selectClientInfo(params) {
		return http.get(`/mds/interceptApi/selectClientInfo`, params);
	},
	// 客户信息-查询条件获取（省）
	getClientInfoProvince(params) {
		return http.get(`/mds/api/clientInfo/getClientInfoProvince`, params);
	},
	// 客户信息-查询条件获取（市）
	getClientInfoCity(params) {
		return http.get(`/mds/api/clientInfo/getClientInfoCity`, params);
	},
	// 客户信息列表
	getClientInfo(params) {
		return http.get(`/mds/api/clientInfo/getClientInfo`, params);
	},
	// 获取客户名称
	getCompanyName(params) {
		return http.get(`/mds/api/getCompanyName`, params);
	},
	// 客户信息详情-加入-移除客户列表
	joinMyClient(params) {
		return http.get(`/mds/interceptApi/clientInfo/joinMyClient`, params);
	},
	// 客户信息详情-订阅-取消订阅
	jsubscribeClient(params) {
		return http.get(`/mds/interceptApi/clientInfo/jsubscribeClient`, params);
	},
	// 客户信息详情-基本信息
	getClientInfoDetail(params) {
		return http.get(`/mds/interceptApi/clientInfo/getClientInfoDetail`, params);
	},
	// 客户信息详情-基本信息-股东信息
	getClientInfoDetailShareholder(params) {
		return http.get(`/mds/interceptApi/clientInfo/getClientInfoDetailShareholder`, params);
	},
	// 客户信息详情-基本信息-主要人员
	getClientInfoDetailPrincipalStaff(params) {
		return http.get(`/mds/interceptApi/clientInfo/getClientInfoDetailPrincipalStaff`, params);
	},
	// 客户信息详情-基本信息-企业对外投资
	getClientInfoDetailInvestment(params) {
		return http.get(`/mds/interceptApi/clientInfo/getClientInfoDetailInvestment`, params);
	},
	// 客户信息详情-基本信息-实际控制企业
	getClientInfoDetailActualControls(params) {
		return http.get(`/mds/interceptApi/clientInfo/getClientInfoDetailActualControls`, params);
	},
	// 客户信息详情-基本信息-分支机构
	getClientInfoDetailBranches(params) {
		return http.get(`/mds/interceptApi/clientInfo/getClientInfoDetailBranches`, params);
	},
	// 客户信息详情-客户动态
	getClientInfoDynamic(params) {
		return http.get(`/mds/interceptApi/clientInfo/getClientInfoDynamic`, params);
	},
	// 客户信息详情-客户动态-详情
	getClientInfoDynamicDetail(params) {
		return http.get(`/mds/interceptApi/clientInfo/getClientInfoDynamicDetail`, params);
	},
	// 客户信息详情-招标信息
	getClientInfoTenderInfo(params) {
		return http.get(`/mds/interceptApi/clientInfo/getClientInfoTenderInfo`, params);
	},
	// 客户信息详情-历史项目数据（招标人）
	getClientInfoHistoryPro(params) {
		return http.get(`/mds/interceptApi/clientInfo/getClientInfoHistoryPro`, params);
	},
	// 客户信息详情-历史项目数据（中标人）
	getClientInfoHistoryProBidWinner(params) {
		return http.get(`/mds/interceptApi/clientInfo/getClientInfoHistoryPro/bidWinner`, params);
	},
	// 客户信息详情-历史项目数据-市
	getClientInfoHistoryProCity(params) {
		return http.get(`/mds/interceptApi/clientInfo/getClientInfoHistoryProCity`, params);
	},
	// 客户信息详情-历史项目数据-区
	getClientInfoHistoryProCounty(params) {
		return http.get(`/mds/interceptApi/clientInfo/getClientInfoHistoryProCounty`, params);
	},
	// 客户信息详情-历史项目数据-按中标人分页
	getClientInfoHistoryBidPage(params) {
		return http.get(`/mds/interceptApi/clientInfo/getClientInfoHistoryBidPage`, params);
	},
	// 客户信息详情-历史项目数据-按地区分页
	getClientInfoHistoryAreaPage(params) {
		return http.get(`/mds/interceptApi/clientInfo/getClientInfoHistoryAreaPage`, params);
	},
	// 客户信息详情-历史项目数据-按类型分页
	getClientInfoHistoryProTypePage(params) {
		return http.get(`/mds/interceptApi/clientInfo/getClientInfoHistoryProTypePage`, params);
	},
	// 客户信息详情-记事本
	getClientInfoNotePad(params) {
		return http.get(`/mds/interceptApi/clientInfo/getClientInfoNotePad`, params);
	},
	// 客户信息详情-添加记事本
	addClientInfoNotePad(params) {
		return http.post(`/mds/interceptApi/clientInfo/addClientInfoNotePad`, params);
	},
	// 客户信息详情-修改记事本
	updateClientInfoNotePad(params) {
		return http.post(`/mds/interceptApi/clientInfo/updateClientInfoNotePad`, params);
	},
	// 客户信息详情-删除记事本
	deleteClientInfoNotePad(params) {
		return http.get(`/mds/interceptApi/clientInfo/deleteClientInfoNotePad`, params);
	},
	// 客户信息详情-客户资料库-文件夹
	getClientInfoArchivesFolder(params) {
		return http.get(`/mds/interceptApi/clientInfo/getClientInfoArchivesFolder`, params);
	},
	// 客户信息详情-客户资料库-文件夹-文件列表
	getClientInfoArchivesFile(params) {
		return http.get(`/mds/interceptApi/clientInfo/getClientInfoArchivesFile`, params);
	},
	// 客户信息详情-下载文件
	getClientInfoDownload(params) {
		return downloads(`/mds/interceptApi/clientInfo/downloadFile`, params);
	},
	// 历史项目数据表格下载
	exportHistoryPro(params) {
		return downloads(`/mds/interceptApi/clientInfo/exportHistoryPro`, params, "post");
	},
	// 历史项目数据表格下载-按中标人视角
	exportStaBidWinnerByTendereeByStr(params) {
		return downloads(`/mds/interceptApi/bid/exportStaBidWinnerByTendereeByStr`, params, "get");
	},
	// 历史项目数据表格下载-按中标人视角new
	exportClientInfoByBidWinner(params) {
		return downloads(`/mds/interceptApi/bid/exportClientInfoByBidWinner`, params, "get");
	},
	// GDP及增速图表数据
	getGdpAndGrowthRate(params) {
		return http.post(`/mds/interceptApi/industry/getGdpAndGrowthRate`, params);
	},
	// 一般公共预算收入及债务负担水平图表数据
	getIncomeAndDebt(params) {
		return http.post(`/mds/interceptApi/industry/getIncomeAndDebt`, params);
	},
	// 人口数量及城镇化率图表数据
	getPopulationAndUrbanizationRate(params) {
		return http.post(`/mds/interceptApi/industry/getPopulationAndUrbanizationRate`, params);
	},
	// 固定资产投资增速图表数据
	getGrowthRateOfFixedAssetsInvestment(params) {
		return http.post(`/mds/interceptApi/industry/getGrowthRateOfFixedAssetsInvestment`, params);
	},
	// 建筑业增加值及GDP占比图表数据
	getValueAddedAndProportionOfGdp(params) {
		return http.post(`/mds/interceptApi/industry/getValueAddedAndProportionOfGdp`, params);
	},
	// 拟建项目信息
	// 拟建项目信息获取查询条件--省份
	getProposedProjectProvince(params) {
		return http.get(`/mds/api/proposedProject/getProposedProjectProvince`, params);
	},
	// 招标信息库-查询条件获取（市）
	getProposedProjectCity(params) {
		return http.get(`/mds/api/proposedProject/getProposedProjectCity`, params);
	},
	// 项目类型获取
	getProjectType(params) {
		return http.get(`/mds/api/proposedProject/getProjectType`, params);
	},
	// 项目阶段获取
	getProjectStage(params) {
		return http.get(`/mds/api/proposedProject/getProjectStage`, params);
	},
	// 拟建项目信息列表
	getProposedProjectPage(params) {
		return http.get(`/mds/api/proposedProject/getProposedProjectPage`, params);
	},
	// 拟建项目信息详情
	getProposedProjectDetail(params) {
		return http.get(`/mds/interceptApi/proposedProject/getProposedProjectDetail`, params);
	},
	// 获取详情查看次数
	getMemberPermission(params) {
		return http.get(`/mds/api/member/getMemberPermission`, params);
	},
	// 获取详情查看总次数
	getModulePermission(params) {
		return http.get(`mds/api/member/getModulePermission`, params);
	},
	// 行业动态查看权限
	getIndustryDynamicInfoApp(params) {
		return http.get(`/mds/api/industry/getIndustryDynamicInfoApp`, params);
	},
	// 中标信息库详情
	getWinningBidDetail(params) {
		return http.get(`/mds/interceptApi/winningBid/getWinningBidDetail`, params);
	},
	// 行业动态获取pdf详情
	getPdfUrl(params) {
		return http.get(`/mds/api/industry/getPdfUrl`, params);
	},
	// 政策规划获取pdf详情
	getPolicyPdfUrl(id) {
		return http.get(`/mds/api/industry/selectPolicyPlanUrlById/${id}`);
	},
	// 客户信息详情-中标动态
	getFindPageForClient(params) {
		return http.get(`/mds/api/winningBid/findPageForClient`, params);
	},
	// 拟建项目筛选时间限制
	getTimeSelector(params) {
		return http.get(`/mds/api/proposedProject/timeSelector`, params);
	},
	// 招标项目筛选时间限制
	getTimeSelectorTender(params) {
		return http.get(`/mds/api/tenderInfo/timeSelector`, params);
	},
	// 中标项目筛选时间限制
	getTimeSelectorWinningBid(params) {
		return http.get(`/mds/api/winningBid/timeSelector`, params);
	},
	// 政策规划筛选时间限制
	getTimeSelectorPolicyPlan(params) {
		return http.get(`/mds/api/policyPlan/timeSelector`, params);
	},
	// 研报筛选时间限制
	getTimeSelectorReport(params) {
		return http.get(`/mds/api/report/buildYearSelector`, params);
	},
	// 研报筛选时间限制
	getTimeSelectorHistory(params) {
		return http.get(`/mds/interceptApi/clientInfo/timeSelector`, params);
	},
	// 行业动态筛选时间限制
	getTimeSelectorIndustry(params) {
		return http.get(`/mds/api/industry/timeSelector`, params);
	},
	// 获取请求数据转成base64
	getParams(params) {
		return http.post(`/mds/api/download/getParams`, params);
	},
	// 土地交易列表
	getLandTransactionPage(params) {
		return http.get(`/mds/api/landTransaction/getLandTransactionPage`, params);
	},
	// 土地交易详情
	getLandTransactionDetail(params) {
		return http.get(`/mds/interceptApi/landTransaction/getLandTransactionDetail`, params);
	},
	getLandTransactionProvince(params) {
		return http.get(`/mds/api/landTransaction/getLandTransactionProvince`, params);
	},
	// 招标信息库-查询条件获取（市）
	getLandTransactionCity(params) {
		return http.get(`/mds/api/landTransaction/getLandTransactionCity`, params);
	},

	// 订阅接收-拟建
	getSubscribeProposedProjectPage(params) {
		return http.get(`/mds/interceptApi/subscribe/receive/getProposedProjectPage`, params);
	},
	// 订阅接收-招标
	getSubscribeTenderInfoPage(params) {
		return http.get(`/mds/interceptApi/subscribe/receive/getTenderInfoPage`, params);
	},
	//订阅接收-土地交易
	getSubscribeLandTransactionPage(params) {
		return http.get(`/mds/interceptApi/subscribe/receive/getLandTransactionPage`, params);
	},
	// 订阅接收-中标
	getSubscribeWinningBidPage(params) {
		return http.get(`/mds/interceptApi/subscribe/receive/getWinningBidPage`, params);
		// 新增/修改 订阅-项目类型获取
	},
	getBidProjectType(params) {
		return http.get(`/mds/api/proposedProject/getProjectType`, params);
	},

	// 判断是否有阅读-下载权限
	getReadAndDownload(params) {
		return http.get(`/mds/interceptApi/industry/getReadAndDownload`, params);
	},
	// 购买行业动态明细
	industryDynamicPayOrderDetail(params) {
		return http.post(`/mds/interceptApi/industry/industryDynamicPayOrderDetail`, params);
	},
	// 行业动态订单支付-new
	industryDynamicPay(params) {
		return http.post(`/mds/interceptApi/industry/industryDynamicPay`, params);
	},
	// 是否是我的客户列表
	isMyClient(params) {
		return http.get(`/mds/interceptApi/consumerClient/isMyClient`, params);
	},
};
